<template>
  <div class="page">
    <el-form size="small" :model="dataForm" class="agentEdit" label-width="120px">
    <div class="page-content">
      <div slot="header" class="clearfix">
        <h3 class="title"> 数据中心 </h3>
      </div>
      <h3>数据上传</h3>
      <el-row>
        <el-col :span="24">
          <el-form-item class="upload">
            <ali-uploader
                :limitSize="limitSize"
                :autoUpload="false"
                :uploadFolder="false"
                :showUploadBtn="false"
                :showFileList="true"
                :multiple="false"
                :limit="1"
                :accept="xlsAccept"
                folderName="xls"
                uploadRef="receipt"
                ref="receipt"
                @getDir="getDir"
                @beforeUpload="beforeUpload"
                @handleChange="handleChange"
                @handleFailed="handleFailed"
                @getUploadUrl="url => (dataForm.data_url = url)"
            ></ali-uploader>

          </el-form-item>
          <el-form-item label="数据类型" >
            <el-select  clearable placeholder="请选择上传数据类型"  v-model="dataForm.data_type">
              <el-option value="1" label="周数据"></el-option>
              <el-option value="2" label="月数据"></el-option>
              <el-option value="3" label="屈臣氏库存数据"></el-option>
              <el-option value="4" label="积分商城物流数据"></el-option>
              <el-option value="5" label="门店数量"></el-option>
              <el-option value="6" label="彩妆爆款周数据"></el-option>
              <el-option value="7" label="屈臣氏店铺列表 "></el-option>
              <el-option value="8" label="REN销售数据 "></el-option>
              <el-option value="9" label="彩妆爆款库存数据 "></el-option>
              <el-option value="20" label="SKU年度预算信息"></el-option>
              <el-option value="21" label="预算台账现金流信息"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传方式" >
            <el-select  clearable placeholder="请选择处理数据方式"  v-model="dataForm.deal_type">
              <el-option value="0" label="追加"></el-option>
              <el-option value="1" label="替换"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <p style="color: #bbb">
            仅限xls、xlsx、csv格式
          </p>
        </el-col>
      </el-row>
    </div>
      <div style="margin-top: 25px;">
        <el-button size="small" type="primary" @click="quitEdit('quit')">取消</el-button>
        <el-button size="small" type="success" @click="handleSave">保 存</el-button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { BAApi } from '@/api'
import simpleOssUpload from '@/mixins/simpleOssUploadMixin'
import AliUploader from '@/components/tools/aliUploader'
import vTable from '@/components/tools/vtable'
export default {
  components: {
    AliUploader,
    vTable
  },
  mixins: [simpleOssUpload],
  data() {
    return {
      title: '添加',
      dataForm: {
        data_url: '',
        data_type:null,
        deal_type:null
      },
      BAList: [],
      limitSize: '10',
      imageUrl: '',
      xlsAccept: 'text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  },
  methods: {
    // 保存
    isEmptyForm(obj) {
      let res = Object.keys(obj).filter(item => {
        if (Array.isArray(item)) {
          return item.length === 0
        } else {
          return !item
        }
      })
      return res.length > 0
    },
    handleSave() {
      if (this.isEmptyForm(this.dataForm)) {
        return this.$message.error('缺少必要信息！')
      }
      if(this.dataForm.data_url === "") {
        return this.$message.error('请选择上传文件')
      }
      if(this.dataForm.data_type === null) {
        return this.$message.error('请选择上传文件类型')
      }
      if(this.dataForm.deal_type === null) {
        return this.$message.error('请选择文件处理类型')
      }
      let params = { ...this.dataForm }

      // 编辑
      this.$confirm('确定保存更改？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.dataImport(params)
      }).catch(() => {})
    },
    async dataImport(params) {
      try {
        let data = (await BAApi.uploadData(params)).data
        if (data.code === 0) {
          this.$message.success('保存成功！')
        } else {
          this.$error(data)
        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    this.title = '数据添加'
    this.thisImageFolder = '0'
  }

}
</script>
<style lang="less" scoped>
.page-content{
  position: relative;
  margin-bottom: 15px;
  h3 {
    margin-bottom: 15px;
  }
  .page-header {
    position: absolute;
    right: 15px;
    top: 15px;
  }
  .tag {
    color: #F56C6C;
  }
  .picList {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .picItem {
      height: 260px;
      width: 180px;
      position: relative;
      background-color: #e6e6e6;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 25px;
      margin-bottom: 25px;
      border-radius: 6px;
      img {
        max-width: 180px;
        max-height: 260px;
        height: auto;
        width: auto;
      }
      .actions {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        height: 45px;
        background-color: rgba(0, 0, 0, .65);
        align-items: center;
        justify-content: space-around;
        border-radius: 0 0 6px 6px;
        i {
          font-size: 22px;
        }
        .del {
          color: #F56C6C;
        }
        .preview {
          color: #409EFF;
        }
      }
    }
  }
}
:deep(.upload .el-form-item__content) {
  margin-left: 0!important;
}
</style>